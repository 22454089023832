$(document).ready(function() {
    $(".trainers__img").colorbox({maxWidth:'95%', maxHeight:'95%'});

    $(".record-form__a").colorbox({inline:true});

    $(".gallery__images a").colorbox({maxWidth:'95%', maxHeight:'95%'});

    $('.menu-toggle-button').click(function() {
        $(this).toggleClass('active');
        $('.navigation').toggleClass('opened');

        return false;
    });
    $(document).click(function() {
        // Закрываем всё
        $('.navigation').removeClass('opened');
        $('.menu-toggle-button').removeClass('active');
    });
    $(window).resize(function() {
        $('.navigation').removeClass('opened');
        $('.menu-toggle-button').removeClass('active');
    });


});
